<template>
  <div>
    <span class="text-uppercase title-block">{{ $t("guide") }}</span>
    <h2 class="content-slogan mt-4">{{ $t("guideline-title-1") }}</h2>
    <div class="title-secondary col-lg-6 px-0">
      {{ $t("guideline-description-1") }}
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 col-md-6 pb-5">
          <div class="content-card" @click="handleClick('overview')">
            <img src="@/assets/image/bg/bg_4.png" />
            <h4 class="m-3">{{ $t("overview") }}</h4>
            <p class="m-24">
              {{ $t("overview-content") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="content-card" @click="handleClick('search-station')">
            <img src="@/assets/image/bg/bg_5.png" />
            <h4 class="m-3">{{ $t("find-station") }}</h4>
            <p class="m-24">
              {{ $t("find-station-content") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="content-card" @click="handleClick('search-router')">
            <img src="@/assets/image/bg/bg_6.png" />
            <h4 class="m-3">{{ $t("route-search") }}</h4>
            <p class="m-24">
              {{ $t("route-search-content") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div style="max-width: 1440px; margin: 120px auto">
      <span class="text-uppercase title-block">
        {{ $t("guideline-title-top-2") }}
      </span>
      <h2 class="content-slogan mt-4">{{ $t("guideline-title-2") }}</h2>
      <div class="title-secondary col-lg-6 px-0">
        {{ $t("guideline-description-2") }}
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('overview')">
              <img src="@/assets/image/bg/bg_4.png" />
              <h4 class="m-3">{{ $t("overview-smartbus") }}</h4>
              <p class="m-24">
                {{ $t("overview-content") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('search-station')">
              <img src="@/assets/image/bg/bg_5.png" />
              <h4 class="m-3">{{ $t("search-station-smartbus") }}</h4>
              <p class="m-24">
                {{ $t("search-station-smartbus-content") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('search-schedule')">
              <img src="@/assets/image/bg/bg_6.png" />
              <h4 class="m-3">{{ $t("waitingtime") }}</h4>
              <p class="m-24">
                {{ $t("waitingtime-content") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('search-router')">
              <img src="@/assets/image/bg/bg_7.png" />
              <h4 class="m-3">{{ $t("route-by-station") }}</h4>
              <p class="m-24">
                {{ $t("route-by-station-content") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('router-info')">
              <img src="@/assets/image/bg/bg_8.png" />
              <h4 class="m-3">{{ $t("router-info") }}</h4>
              <p class="m-24">
                {{ $t("router-info-content") }}
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 pb-5">
            <div class="content-card" @click="handleClick('search-schedule')">
              <img src="@/assets/image/bg/bg_9.png" />
              <h4 class="m-3">{{ $t("schedule-search") }}</h4>
              <p class="m-24">
                {{ $t("schedule-search-content") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick(route) {
      this.$router.push(`/guideline/${route}`);
    },
  },
};
</script>

<style scoped>
.m-24{
  margin: 24px;
}
.font-64 {
  font-size: 64px;
}
.font-24 {
  font-size: 24px;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.content-card {
  border: 1px solid #e6e8ec;
  border-radius: 24px;
  overflow: hidden;
  transition: 500ms;
  cursor: pointer;
  /* height: 477px; */
}
.content-card h4{
  font-size: 24px !important;
  font-weight: 500;
}
.content-card p {
  max-height: 200px !important;
  text-overflow: ellipsis;
  overflow: hidden !important;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Số dòng tối đa */
  -webkit-box-orient: vertical;
}
.content-card:hover {
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
}
</style>
